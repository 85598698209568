/**
 * Docs: https://graphcommerce.org/docs/framework/config
 *
 * @type {import('@graphcommerce/next-config/src/generated/config').GraphCommerceConfig}
 */

const config = {
  hygraphEndpoint: 'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cllp79ij8155g01umhipdc5ar/master',
  // hygraphEndpoint: 'https://eu-central-1.cdn.hygraph.com/content/ckhx7xadya6xs01yxdujt8i80/master',

  magentoEndpoint: 'https://ordme-backend-develop.rayancommerce.com/graphql',
  canonicalBaseUrl: 'https://ordme-backend-develop.rayancommerce.com/',

  // magentoEndpoint: 'https://ordme.rayancommerce.com/graphql',
  // canonicalBaseUrl: 'https:/ordme.rayancommerce.com',

  storefront: [{ defaultLocale: true, locale: 'fa', magentoStoreCode: 'default' }],
  // storefront: [{
  //   defaultLocale: true,
  //   locale: 'fa',
  //   magentoStoreCode: 'fa_ir'
  // }, { locale: 'en', magentoStoreCode: 'en_us' }],
  debug: {
    pluginStatus: true,
  },
  compare: true,
  compareVariant: 'ICON'


}

module.exports = config

